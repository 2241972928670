<template>
  <div class="content-wrapper">
    <!-- Category Buttons -->
    <div class="category-buttons sticky-top">
      <button v-for="category in DPAData" :key="category.category" class="btn btn-outline-secondary"
        @click="scrollToCategory(category.category)" :class="{ active: activeCategory === category.category }">
        {{ category.category }}
      </button>
    </div>

    <!-- Product Listings -->
    <div class="product-listings">
      <div v-for="category in DPAData" :key="category.category" :id="category.category">
        <h1 class="text-center display-2">{{ category.category }}</h1>
        <div class="d-flex flex-wrap justify-content-center">
          <div v-for="product in category.products" :key="product.id" class="DPA-card">
            <div class="row product-page-card">
              <div class="col-md-6">
                <div class="carousel slide" :id="'carousel' + product.id" data-bs-ride="carousel">
                  <div class="carousel-inner">
                    <div v-for="(image, index) in product.images" :key="image"
                      :class="['carousel-item', { active: index === 0 }]">
                      <img :src="getImagePath(image)" :alt="product.title">
                    </div>
                  </div>
                  <button class="carousel-control-prev" type="button" :data-bs-target="'#carousel' + product.id"
                    data-bs-slide="prev">
                    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span class="visually-hidden">Previous</span>
                  </button>
                  <button class="carousel-control-next" type="button" :data-bs-target="'#carousel' + product.id"
                    data-bs-slide="next">
                    <span class="carousel-control-next-icon" aria-hidden="true"></span>
                    <span class="visually-hidden">Next</span>
                  </button>
                </div>
              </div>
              <div class="col-md-6">
                <h1>{{ product.title }}</h1>
                <p>{{ product.description }}</p>
                <div v-if="product.qualityStandards && product.qualityStandards.length">
                  <h4><strong>Quality Standard</strong></h4>
                  <ul>
                    <li v-for="(standard, index) in product.qualityStandards" :key="index">
                      <strong>{{ standard.standard }}:</strong> {{ standard.description }}

                    </li>
                  </ul>
                </div>
                <div v-if="product.orderingInformation && product.orderingInformation.length">
                  <h4><strong>Ordering Information</strong></h4>
                  <table class="table table-striped table-sm caption-top">
                    <thead>
                      <tr>
                        <th v-for="(key, index) in Object.keys(product.orderingInformation[0])" :key="index">
                          {{ formatHeader(key) }}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(info, index) in product.orderingInformation" :key="index">
                        <td v-for="(value, key) in info" :key="key">{{ value }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import DPAData from '@/data/disposableProtectiveApparel.json';

export default {
  data() {
    return {
      DPAData: DPAData, // Load all product data
    };
  },
  methods: {
    getImagePath(imagePath) {
      return require('@/' + imagePath); // Use require to resolve the path
    },
    scrollToCategory(category) {
      this.activeCategory = category
      const element = document.getElementById(category);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' }); // Smooth scrolling
      }
    },
    formatHeader(header) {
      return header
        .replace(/([A-Z])/g, ' $1') // Add space before capital letters
        .replace(/^./, (str) => str.toUpperCase()); // Capitalize the first letter
    }
  }
}
</script>

<style scoped>
.category-buttons {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  padding:.25rem;
  flex-wrap: wrap;
}

.category-buttons .btn {
  margin: 0.5rem;
  white-space: nowrap;
}
@media (max-width: 768px) {
  .category-buttons{
    max-width: 60vw;
  }
  .category-buttons .btn {
    font-size: 0.7rem;
    /* Adjust as needed */
    padding: 0.1rem 0.1rem;
    /* Adjust as needed */
    margin-bottom: 5px;
    margin: 2px;
    max-width: 70vh;
  }
}
.category-buttons .btn.active {
  background-color: #007bff;
  color: white;
}



.DPA-card {
  width: 100%;
  max-width: 800px;
  margin: 20px;
}


.carousel-inner img {
  width: 100%;
  height: auto;
}

.table-container {
  margin-top: 20px;
}

</style>
