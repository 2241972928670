/<template>
  <section style="background-color: #1f4641" class="text-white">
    <div class="row g-0">
      <div class="col-md-6">
        <div class="container-fluid">
          <img
            class="img-fluid"
            style="width: 50vw; height: auto"
            src="@/assets/img/file.jpg"
            alt="Lab Technician"
          />
        </div>
      </div>
      <div class="col-md-6">
        <div class="m-4">
          <div class="container-fluid">
            <h1 class="display-5">
              More Than Medical Supplies: Partnership, Expertise, and Solutions.
            </h1>
            <p class="mission">
              At DevAfri Health, we're more than just a supplier. We're your
              dedicated partner, committed to understanding and exceeding your
              medical supply needs. Our priority is delivering consistent,
              high-quality products that prioritize patient well-being and your
              bottom line. We leverage supply chain expertise and a passion for
              innovation to empower you in providing exceptional care. With
              DevAfri Health, you gain a partner invested in your success and
              the health of your community.
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {};
</script>

<style scoped>
.mission{
  padding-top: 2rem;
}
p {
  font-size: 1.1rem;
  font-family: 'Montserrat';
}
</style>