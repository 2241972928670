<template>
  <div>
    <NavBar />
    <HeroBanner :category="category" />
    <router-view :key="$route.fullPath" />
    <FooterSection />
  </div>
</template>

<script>
import NavBar from "@/components/NavBar.vue";
import HeroBanner from "@/components/HeroBanner.vue";
import FooterSection from "@/components/FooterSection.vue";

export default {
  name: "ProductDetails",
  components: {
    NavBar,
    HeroBanner,
    FooterSection,
  },
  computed: {
    category() {
      return this.$route.params.category;
    },
  },
};
</script>