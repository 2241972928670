<template>
  <HomeSection />
  <AboutUsSection />
  <ProductsSection />
  <MissionSection />
  <ContactSection />
</template>

<script>
import HomeSection from "@/components/HomeSection.vue";
import AboutUsSection from "@/components/AboutUsSection.vue";
import ProductsSection from "@/components/ProductsSection.vue";
import MissionSection from "@/components/MissionSection.vue";
import ContactSection from "@/components/ContactSection.vue";

export default {
  name: "Home",
  components: {
    HomeSection,
    AboutUsSection,
    ProductsSection,
    MissionSection,
    ContactSection,
  },
};
</script>
