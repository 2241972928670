<template>
    <div class="container" style="border-bottom: 5px; border-color: var(--bs-teal);">
        <h2 class="display-1">Contact Us</h2>
    </div>

    <div class="contact-us-container">

        <b-form @submit.prevent="handleSubmit">
            <b-form-group id="nameFormGroup" label="Name" label-for="name">
                <b-form-input v-model="form.name" type="text" id="name" required></b-form-input>
            </b-form-group>
            <b-form-group id="companyFormGroup" label="Company" label-for="company">
                <b-form-input v-model="form.company" type="text" id="company" required></b-form-input>
            </b-form-group>
            <b-form-group id="emailFormGroup" label="Email address" label-for="email">
                <b-form-input v-model="form.email" type="email" id="email" required></b-form-input>
            </b-form-group>
            <b-form-group id="messageFormGroup" label="Message" label-for="message">
                <b-form-textarea v-model="form.message" id="message" rows="3" required></b-form-textarea>
                <div class="g-recaptcha" data-sitekey="6LfTNRoqAAAAAClzlmvqtiP5LY42hGC9b0WRj1A8"></div>

            </b-form-group>
            <b-button type="submit" variant="primary">Submit</b-button>
        </b-form>
    </div>
</template>

<script>
import axios from "axios";
// Import BootstrapVue components
import {
    BForm,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BButton,
} from "bootstrap-vue-3";

export default {
    components: {
        BForm,
        BFormGroup,
        BFormInput,
        BFormTextarea,
        BButton,
    },
    data() {
        return {
            form: {
                name: "",
                company:"",
                email: "",
                message: "",
            },
            submissionStatus: null, // Added to track submission status
        };
    },
    methods: {
        async handleSubmit() {
            this.submissionStatus = "submitting"; // Set status to 'submitting'

            try {
                const response = await axios.post(
                    "https://5u23s8ni4g.execute-api.us-east-2.amazonaws.com/prod/contact", // Replace with your actual API endpoint
                    this.form,
                    {
                        headers: {
                            "Content-Type":"application/json"
                        }
                    }
                );

                // Check response from Lambda function
                if (response.status === 200) {
                    this.submissionStatus = "success"; // Set status to 'success'
                    alert("Form submitted successfully!");
                    this.form = { name: "",company:"", email: "", message: "" };
                } else {
                    this.submissionStatus = "error"; // Set status to 'error'
                    throw new Error("There was an error submitting your form.");
                }
            } catch (error) {
                console.error("Error submitting form:", error);
                this.submissionStatus = "error"; // Set status to 'error'
                alert(error.message); // Use a more informative error message from the server if available
            }
        },
    },
};
</script>

<style scoped>
.container {
    border-bottom: 5px solid var(--bs-teal);
    padding-bottom: 1rem;
    margin-bottom: 2rem;
}

h2 {
    color: var(--bs-teal);
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
}

.contact-us-container {
    max-width: 700px;
    margin: 20px auto;
    padding: 30px;
    background-color: #e0f2f1;
    border-radius: 12px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    font-family: 'Lato', sans-serif;
        color: #6a747d;
            font-weight: 495057;
}


.form-control,
.form-textarea {
    border-radius: 8px;
    background: #f8f9fa;
    border: 1px solid #ced4da;
}

.btn-primary {
    background-color: var(--bs-teal);
    border-color: var(--bs-teal);
    transition: background-color 0.3s ease;
}

.btn-primary:hover {
    background-color: #00695c;
    border-color: #00695c;
    color: #fff;
}

.text-center {
    display: flex;
    flex-direction: column;
    align-items: center;
}

</style>