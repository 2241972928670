<template>
  <div class="not-found-container">
    <div class="content-wrapper">
      <i class="fas fa-exclamation-triangle fa-4x" style="color: #dc3545"></i>
      <h2>Oops! Page Not Found</h2>
      <h3>404 Error</h3>
      <p>The page you are looking for doesn't exist or has been moved.</p>
      <RouterLink to="/" class="btn btn-primary">Back to Home</RouterLink>
    </div>
  </div>
</template>

<script>
import { RouterLink } from "vue-router";
export default {
  name: "NotFound",
  components: { RouterLink },
};
</script>

<style scoped>
.not-found-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 55vh; /* More space for visual elements */
  text-align: center;
  background-color: #f8f9fa; /* Light background for better contrast */
  padding: 20px;
}

.error-image {
  max-width: 300px; /* Adjust as needed */
  margin-bottom: 20px;
}

h2 {
  font-size: 2.5rem;
  color: #dc3545; /* Error red */
  margin-bottom: 10px;
}

h3 {
  font-size: 1.8rem;
  color: #343a40; /* Dark gray */
  margin-bottom: 20px;
}

p {
  font-size: 1.2rem;
  color: #6c757d; /* Gray */
  margin-bottom: 30px;
}

.btn-primary {
  background-color: var(--bs-teal);
  border-color: var(--bs-teal);
}
</style>
