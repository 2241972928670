<template>
  <div class="content-wrapper">
    <div v-for="product in stethoscopeData" :key="product.id" class="stethoscope-card">
      <div class="row product-page-card">
        <div class="col-md-6">
              <div class="carousel slide" :id="'carousel'+product.id" data-bs-ride="carousel">
        <div class="carousel-inner">
          <div v-for="(image, index) in product.images" :key="image" :class="['carousel-item', { active: index === 0}]">
            <img :src="getImagePath(image)" :alt="product.title">
          </div>
        </div>
        <button class="carousel-control-prev" type="button" :data-bs-target="'#carousel'+ product.id" data-bs-slide="prev">
          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Previous</span>
        </button>
        <button class="carousel-control-next" type="button" :data-bs-target="'#carousel'+ product.id" data-bs-slide="next">
          <span class="carousel-control-next-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Next</span>
        </button>
      </div>
        </div>
        <div class="col-md-6">
                <h1>{{ product.title }}</h1>
      <p>{{ product.description }}</p>
              <h3>Ordering Information</h3>
      <div class="table-container">  
        <table class="table table-striped">
          <thead>
            <tr>
              <th>Item Number</th>
              <th>Product</th>
              <th>Color</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="variant in product.variants" :key="variant.itemNumber">
              <td>{{ variant.itemNumber }}</td>
              <td>{{ variant.product }}</td>
              <td>{{ variant.color }}</td>
            </tr>
          </tbody>
        </table>
        </div>
        </div>
      </div>
      


 

    </div>
  </div>
</template>

<script>
import stethoscopeData from '@/data/stethoscopes.json';

export default {
  data() {
    return {
      stethoscopeData: stethoscopeData, // Load all stethoscope data
    };
  },
  methods: {
    getImagePath(imagePath) {
      return require('@/' + imagePath); // Use require to resolve the path
    },
  },
}
</script>

<style scoped>
.stethoscope-card {
  width: 100%;
  max-width: 800px;
  margin: 20px;
}
</style>
