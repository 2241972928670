<template>
  <div class="content-wrapper">
    <div v-for="product in examGlovesData" :key="product.id" class="exam-gloves-card">
      <div class="row product-page-card">
        <div class="col-md-6">
              <div class="carousel slide" :id="'carousel'+product.id" data-bs-ride="carousel">
        <div class="carousel-inner">
          <div v-for="(image, index) in product.images" :key="image" :class="['carousel-item', { active: index === 0}]">
            <img :src="getImagePath(image)" :alt="product.title">
          </div>
        </div>
        <button class="carousel-control-prev" type="button" :data-bs-target="'#carousel'+ product.id" data-bs-slide="prev">
          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Previous</span>
        </button>
        <button class="carousel-control-next" type="button" :data-bs-target="'#carousel'+ product.id" data-bs-slide="next">
          <span class="carousel-control-next-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Next</span>
        </button>
      </div>
        </div>
        <div class="col-md-6">
                <h1>{{ product.title }}</h1>
      <p>{{ product.description }}</p> 
      <div class="table-container">  
        <table class="table table-striped table-sm">
          <caption>Tested for use with Chemotherapy Drugs (ASTM D6978) Meets USP 800 Personal Protective Equipment requirements</caption>
            <thead>
            <tr>
              <th>Gauge Thickness Measurements</th>
              <th>MIL</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="variant in product.gaugeThickness" :key="variant.measurement">
                <td>{{ variant.measurement }}</td>
                <td>{{ variant.MIL }}</td>
            </tr>
            <tr v-for="variant in product.qualityStandards" :key="variant.standard">
              <td>{{ variant.description }}</td>
            </tr>
          </tbody>
        </table>
        <table class="table table-striped table-sm caption-top">
            <caption><strong>Ordering Information</strong></caption>
          <thead>
            <tr>
              <th>Size</th>
              <th>Item Number</th>
              <th>Packaging</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="variant in product.orderingInformation" :key="variant.itemNumber">
              <td>{{ variant.size }}</td>
              <td>{{ variant.itemNumber }}</td>
              <td>{{ variant.packaging }}</td>
            </tr>
          </tbody>
        </table>
        </div>
        
        <!-- Extended Cuff Table-->
         <div class="table-container">  
            <p> <strong>Extended Cuff Option: </strong>For tasks requiring additional protection, our nitrile exam gloves are also available with an extended cuff design, offering enhanced coverage and security without compromising comfort or dexterity.</p>
        <table class="table table-striped table-sm">
          <caption>Tested for use with Chemotherapy Drugs (ASTM D6978) Meets USP 800 Personal Protective Equipment requirements</caption>
            <thead>
            <tr>
              <th>Gauge Thickness Measurements</th>
              <th>MIL</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="variant in product.gaugeThickness" :key="variant.measurement">
                <td>{{ variant.measurement }}</td>
                <td>{{ variant.MIL }}</td>
            </tr>
            <tr v-for="variant in product.qualityStandards" :key="variant.standard">
              <td>{{ variant.description }}</td>
            </tr>
          </tbody>
        </table>
        <table class="table table-striped table-sm caption-top">
            <caption><strong>Ordering Information</strong></caption>
          <thead>
            <tr>
              <th>Size</th>
              <th>Item Number</th>
              <th>Packaging</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="variant in product.orderingInformation" :key="variant.itemNumber">
              <td>{{ variant.size }}</td>
              <td>{{ variant.itemNumber }}</td>
              <td>{{ variant.packaging }}</td>
            </tr>
          </tbody>
        </table>
        </div>
      </div>
      
</div>

 

    </div>
  </div>
</template>

<script>
import examGlovesData from '@/data/examGloves.json';

export default {
  data() {
    return {
      examGlovesData: examGlovesData, // Load all product data
    };
  },
  methods: {
    getImagePath(imagePath) {
      return require('@/' + imagePath); // Use require to resolve the path
    },
  },
}
</script>

<style scoped>
.exam-gloves-card {
  width: 100%;
  max-width: 800px;
  margin: 20px;
}
</style>


