import { createRouter, createWebHistory } from 'vue-router';

// Import views
import Home from '@/views/Home.vue';
import ContactUs from "@/views/ContactUs.vue";
import Products from '@/views/Products.vue';
import DisposableProtectiveApparel from '@/components/ProductCategories/DisposableProtectiveApparel.vue';
import ExamGloves from '@/components/ProductCategories/ExamGloves.vue';
import FallManagementFootwear from '@/components/ProductCategories/FallManagementFootwear.vue';
import IncontinenceProducts from '@/components/ProductCategories/IncontinenceProducts.vue';
import PressureInfusionBags from '@/components/ProductCategories/PressureInfusionBags.vue';
import Stethoscopes from '@/components/ProductCategories/Stethoscopes.vue';
import NotFound from '@/views/NotFound.vue';

const routes = [
  { path: "/", name: "Home", component: Home },
  { path: "/contact", name: "ContactUs", component: ContactUs },
  { path: "/products", name: "AllProducts", component: Products, props: true },
  {
    path: "/products/:category",
    name: "Products",
    component: Products,
    props: true,
    children: [
      {
        path: "disposable-protective-apparel",
        name: "DisposableProtectiveApparel",
        component: DisposableProtectiveApparel,
      },
      { path: "exam-gloves", name: "ExamGloves", component: ExamGloves },
      {
        path: "fall-management-footwear",
        name: "FallManagementFootwear",
        component: FallManagementFootwear,
      },
      {
        path: "incontinence-products",
        name: "IncontinenceProducts",
        component: IncontinenceProducts,
      },
      {
        path: "pressure-infusion-bags",
        name: "PressureInfusionBags",
        component: PressureInfusionBags,
      },
      { path: "stethoscopes", name: "Stethoscopes", component: Stethoscopes },
    ],
  },
  { path: "/:catchAll(.*)", name: "NotFound", component: NotFound },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(/* to, from, savedPosition */) {
    return {top: 0}
  }
});

export default router;
