<template>
  <section id="Home" class="hero-banner">
      <picture>

        <img src="@/assets/img/topBackground2.jpg" alt="Banner" style="" class="hero-image" />
      </picture>
      <div class="text-overlay">
        <h1>
          Trusted by Healthcare Professionals<br />
          for Quality and Consistency.
        </h1>
        <router-link :to="{ name: AllProducts }" class="btn btn-warning btn-lg">Explore
          Products</router-link>
      </div>
  </section>
</template>

<script>
export default {
  name: "HeroBanner",
};
</script>

<style scoped>

.hero-banner {
  background-color: #f2f2f2;
  /* Light blue background */
  position: relative;
  min-height: 50vh;
  /* Adjust height as needed */
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.hero-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}

/* Text Overlay */
.text-overlay {
  position: relative;
  z-index: 2;
  text-align: center;
  padding: 1rem;
  border-radius: 0.2rem;
  width: 100%;
  background-color: rgba(31, 70, 65, 0.7);
  min-height: 50vh;
  /* Slightly lighter overlay */
}

.text-overlay h1 {
  font-size: 2.0rem;
  /* Adjust font size for optimal impact */
  margin-bottom: 1.5rem;
  color: #fff;
  font-family: 'Lato', sans-serif;
  /* Use Lato font if available */
  font-weight: 400;
  /* Bolder font weight */

}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .text-overlay {
    padding: 0.4rem;
  }

  .text-overlay h1 {
    font-size: 1.8rem;
    margin-bottom: 1.2rem;
    padding-top: 4rem;
  }

  .lead {
    font-size: 1.2rem;
    margin-bottom: 1.5rem;
  }

  .btn-warning.btn-lg {
    padding: 0.5rem 0.5rem;
    font-size: 1.2rem;
    border-radius:0px;
  }

}
</style>
