<template>
    <section class="bg-custom-teal text-white p-5">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-12 about-us-content">
                    <div class="heading-col">
                        <h1 class="display-4 text-white mb-4">Empowering Healthcare,<br>One Solution at a Time.</h1>
                    </div>
                    <div class="paragraph-col">
                        <p class="lead">
                            At DevAfri Health, we know medical supplies are the lifeline of patient care. They're not
                            just
                            products on a shelf; they're the tools that enable healing and recovery.
                        </p>
                        <p class="lead">
                            That's why we offer a comprehensive approach to medical supply distribution, prioritizing
                            your
                            needs and the well-being of your patients. Our curated selection of high-quality products is
                            designed to streamline your procurement process, reduce costs, and enhance efficiency.
                        </p>
                        <p class="lead">
                            We're more than just a supplier; we're your trusted partner in healthcare. We leverage our
                            industry expertise and commitment to innovation to deliver solutions that empower you to
                            focus
                            on what matters most – providing exceptional patient care.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<style scoped>
.bg-custom-teal {
    /* Example gradient */
    color: #f0f0f0;
    /* Lighter text color for better contrast */
    padding: 4rem 0;
    /* Increased padding */
}

.container {
    max-width: 960px;
    /* Adjust as needed */
}

h1 {
    font-family: 'Montserrat', sans-serif;
    /* Example modern font */
    font-weight: 700;
    margin-bottom: 2rem;
}

p.lead {
    font-size: 1.1rem;
    line-height: 1.6;
}

/* Style adjustments for the two-column layout */
.about-us-content {
    display: grid;
    grid-template-columns: 1fr 1.5fr;
    gap: 3rem;
    align-items: center;
}

.heading-col {
    /* Style for the left column (heading) */
    text-align: right;
    padding-right: 3rem;
}

.paragraph-col {
    /* Style for the right column (paragraphs) */
    text-align: left;
    padding-left: 3rem;
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .about-us-content {
        grid-template-columns: 1fr;
        /* Single column on smaller screens */
    }

    .heading-col {
        text-align: center;
        padding-right: 0;
    }

    .paragraph-col {
        text-align: center;
        padding-left: 0;
        margin-top: 1rem;
    }
}
</style>
