<template>
  <footer class="bs-teal text-white pt-5 pb-4">
    <div class="container">
      <div class="row">
        <div class="col-md-4">
          <h3>DevAfri Health</h3>
          <p>Empowering Healthcare, One Solution at a Time.</p>
        </div>
        <div class="col-md-4">
          <h3>Quick Links</h3>
          <ul class="list-unstyled">
            <li><router-link to="/" class="text-white">Home</router-link></li>
            <li><router-link to="/products" class="text-white">Products</router-link></li>
            <li><router-link to="/contact" class="text-white">Contact Us</router-link></li>
          </ul>
        </div>
        <div class="col-md-4">
          <h3>Locations & Contact</h3>
          <p>
            Accra, Ghana<br />
            Lagos, Nigeria<br />
            Minneapolis, MN USA<br />
            <a href="mailto:info@devafri.com" class="text-white"
              >info@devafri.com</a
            >
          </p>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-12 text-center">
          <p class="small">&copy; 2024 DevAfri Health. All rights reserved.</p>
        </div>
      </div>
    </div>
    <a href="#Home" class="scroll-top">
      <i class="bi bi-arrow-up-circle h1 text-white"></i>
    </a>
  </footer>
</template>

<script>
export default {
  name: "FooterSection",
};
</script>

<style scoped>
/* Add your specific styles here */
</style>
