<template>
  <section id="Home" class="hero-banner">
    <picture>

      <img src="@/assets/img/topBackground2.jpg" alt="Banner" style="" class="hero-image" />
    </picture>
    <div class="text-overlay">
      <h1>
        Trusted by Healthcare Professionals<br />
        for Quality and Consistency.
      </h1>
      <router-link :to="{ name: AllProducts }" class="btn btn-warning btn-lg">Explore
        Products</router-link>
    </div>
  </section>
</template>

<script>
export default {
  name: "HomeSection",
};
</script>

<style scoped>
.hero-banner {
  background-color: #f2f2f2;
  /* Light blue background */
  position: relative;
  min-height: 50vh;
  /* Adjust height as needed */
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  background-image: linear-gradient(to right, #4D8886, #28a745)
}

.hero-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}

/* Text Overlay */
.text-overlay {
  position: relative;
  z-index: 2;
  text-align: center;
  padding: 1rem;
  border-radius: 0.2rem;
  width: 100%;
  background-color: rgba(31, 70, 65, 0.7);
  min-height: 50vh;
  /* Slightly lighter overlay */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    /* Subtle shadow */
  text-align: left;
    /* Align text to the left */
}

.text-overlay h1 {
  font-size: 2.8rem;
  /* Adjust font size for optimal impact */
  margin-bottom: 1.5rem;
  color: #fff;
  font-family: 'Montserrat', sans-serif;
    /* Example modern font */
  font-weight: 700;
  letter-spacing: 2px;
  margin-left: 2rem;
  padding-top: 5rem;

}

.text-overlay .btn-warning.btn-lg{
  margin-left: 2rem;
}
.btn-warning:hover {
  background-color: #ffc107;
  /* Bootstrap primary color */
  border-color: #ffc107;
  /* Slightly darker primary color on hover */
  box-shadow: 0px 6px 8px rgba(0, 0, 0, 0.15);
  /* Increased shadow on hover */
  color:white;
  transform: scale(1.05);
}
.btn-warning {
  /* ... Existing styles ... */
  background-color: #ff9800;
  /* Example: Orange button */
  border-color: #ff9800;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .text-overlay {
    padding: 0.4rem;
    text-align: center;
  }

  .text-overlay h1 {
    font-size: 2rem;
    margin-bottom: 1.2rem;
    padding-top: 5rem;
  }

  .btn-warning.btn-lg {
    padding: .7rem 0.8rem;
    font-size: 1.2rem;
    border-radius: 50px;
    
  }

}
</style>
