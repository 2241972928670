    <template>
        <b-modal v-model="showModal" title="Contact Us" @ok="handleSubmit">
            <b-form @submit.prevent="handleSubmit">
                <b-form-group id="nameFormGroup" label="Name" label-for="name">
                    <b-form-input v-model="form.name" type="text" id="name" required></b-form-input>
                </b-form-group>
                <b-form-group id="emailFormGroup" label="Email address" label-for="email">
                    <b-form-input v-model="form.email" type="email" id="email" required></b-form-input>
                </b-form-group>
                <b-form-group id="messageFormGroup" label="Message" label-for="message">
                    <b-form-textarea v-model="form.message" id="message" rows="3" required></b-form-textarea>
                </b-form-group>
                <b-button type="submit" variant="primary">Submit</b-button>
            </b-form>
        </b-modal>

    </template>
<script>
import axios from "axios";
// Import BootstrapVue components
import {
    BModal,
    BForm,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BButton,
} from "bootstrap-vue-3";

export default {
    name: "ContactSection",
    components: {
        BModal,
        BForm,
        BFormGroup,
        BFormInput,
        BFormTextarea,
        BButton,
    },
    data() {
        return {
            showModal: false,
            form: {
                name: "",
                email: "",
                message: "",
            },
            submissionStatus: null, // Added to track submission status
        };
    },
    methods: {
        async handleSubmit() {
            this.submissionStatus = "submitting"; // Set status to 'submitting'

            try {
                const response = await axios.post(
                    "https://your-api-endpoint",
                    this.form
                );

                // Check response from Lambda function
                if (response.status === 200) {
                    this.submissionStatus = "success"; // Set status to 'success'
                    alert("Form submitted successfully!");
                    this.form = { name: "", email: "", message: "" };
                } else {
                    this.submissionStatus = "error"; // Set status to 'error'
                    throw new Error("There was an error submitting your form.");
                }
            } catch (error) {
                console.error("Error submitting form:", error);
                this.submissionStatus = "error"; // Set status to 'error'
                alert(error.message); // Use a more informative error message from the server if available
            }
        },
    },
};
</script>
