<template>
  <section id="Products" class="p-5 bg-light">
    <div class="container">
      <h1 class="display-4 text-center mb-5">Our Medical Supply Solutions</h1>
      <div class="row row-cols-1 row-cols-md-2 row-cols-lg-3 g-4">
        <ProductCard
          v-for="product in products"
          :key="product.path"
          :product="product"
        />
      </div>
    </div>
  </section>
</template>

<script>
import ProductCard from "./ProductCard.vue";

export default {
  name: "ProductsSection",
  components: { ProductCard },
  data() {
    return {
      products: [
        {
          title: "Disposable Protective Apparel",
          description:
            "Designed by infection prevention leaders for maximum protection.",
          path: "/disposable-protective-apparel",
        },
        {
          title: "Exam Gloves",
          description:
            "Single-use exam glove varieties that meet USP 800 Personal Protective Equipment requirements.",
          path: "/exam-gloves",
        },
        {
          title: "Fall Management Footwear",
          description: "Comfort and Safety in Every Step",
          path: "/fall-management-footwear",
        },
        {
          title: "Incontinence Products",
          description:
            "Absorb moisture and protect patients’ skin with high-quality underpads in three varieties.",
          path: "/incontinence-products",
        },
        {
          title: "Pressure Infusion Bags",
          description: "Rapid, reliable infusion and monitoring solutions.",
          path: "/pressure-infusion-bags",
        },
        {
          title: "Stethoscopes",
          description: "Hear every detail, care for every patient.",
          path: "/stethoscopes",
        },
      ],
    };
  },
};
</script>

<style scoped>
/* Main Section Styling */
section#Products {
  background-color: #f5f5f5;
  /* Light gray background */
  /* Or use a subtle gradient: background-image: linear-gradient(to bottom, #f8f9fa, #e9ecef); */
}

h1 {
  font-size: 2.2rem;
  /* Slightly larger heading */
  font-weight: 700;
  /* Bolder font weight */
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
  /* Subtle text shadow */
  margin-bottom: 3rem;
}

/* Product Card Styling */
.product-card {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  /* Smooth transitions */
}

.product-card:hover {
  transform: translateY(-5px);
  /* Slight lift on hover */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
  /* Increased shadow on hover */
}
</style>
