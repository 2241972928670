<template>
  <div class="col">
    <div class="card h-100">
      <div class="card-body d-flex flex-column">
        <h5 class="card-title">{{ product.title }}</h5>
        <p class="card-text">{{ product.description }}</p>
        <RouterLink
          :to="{ name:'Products', params:{category: product.path.replace(/^\/+/, '') }} "
          class="btn btn-primary mt-auto"
          >Learn More</RouterLink
        >
      </div>
    </div>
  </div>
</template>

<script>
import { RouterLink } from "vue-router";
export default {
  name: "ProductCard",
  props: {
    product: Object,
  },
  components: {
    RouterLink,
  },
  methods: {
    getRouteName(title) {
      // Split the title by spaces and hyphens
      const words = title.split(/[\s-]+/);
      // Capitalize the first letter of each word and join them
      return words
        .map(
          (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
        )
        .join("");
    },
  },
};
</script>

<style scoped>
/* Product Card Styling */
.product-card {
  border: none;
  /* Remove default border */
  border-radius: 12px;
  /* Slightly larger border radius */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  /* Subtle shadow */
  overflow: hidden;
  /* Prevent content from overflowing */
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  /* Add smooth transitions */
}

.product-card:hover {
  transform: translateY(-3px);
  /* Slight lift on hover */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
  /* Increased shadow on hover */
}

.card-body {
  padding: 1.5rem 2rem;
  /* Adjust padding as needed */
}

.card-title {
  font-family: 'Lato', sans-serif;
  /* Use your chosen font */
  font-weight: 600;
  /* Slightly bolder title */
  margin-bottom: 1rem;
}

.card-text {
  font-size: 0.9rem;
  /* Slightly smaller description text */
  line-height: 1.5;
  /* Improve readability with better line height */
  margin-bottom: 1.5rem;
  color: #212529;
}

.btn-primary {
  background-color: var(--bs-teal);
  /* Use your primary brand color */
  border-color: var(--bs-teal);
  font-weight: 500;
  transition: background-color 0.3s ease;
  font-size: 1rem;
    color: #fff;
      /* Slightly larger button text */
}

.btn-primary:hover {
    filter: brightness(80%);
    
}
</style>
