<template>
  <ProductBanner />

  <div v-if="!$route.params.category" class="no-category-message container">
    <ul class="category-list">
      <li><router-link to="/products/stethoscopes">Stethoscopes</router-link></li>
      <li><router-link to="/products/exam-gloves">Exam Gloves</router-link></li>
      <li><router-link to="/products/fall-management-footwear">Fall Management Footwear</router-link></li>
      <li><router-link to="/products/incontinence-products">Incontinence Products</router-link></li>
      <li><router-link to="/products/pressure-infusion-bags">Pressure Infusion Bags</router-link></li>
      <li><router-link to="/products/disposable-protective-apparel">Disposable Protective Apparel</router-link></li>
    </ul>
  </div>

  <component :is="currentComponent" />
</template>


<script>
import ProductBanner from "@/components/productComponents/ProductBanner.vue";
import Stethoscopes from "@/components/ProductCategories/Stethoscopes.vue";
import DisposableProtectiveApparel from "@/components/ProductCategories/DisposableProtectiveApparel.vue";
import ExamGloves from "@/components/ProductCategories/ExamGloves.vue";
import FallManagementFootwear from "@/components/ProductCategories/FallManagementFootwear.vue";
import IncontinenceProducts from "@/components/ProductCategories/IncontinenceProducts.vue";
import PressureInfusionBags from "@/components/ProductCategories/PressureInfusionBags.vue";

export default {
  name: "AllProducts",
  components: {
    ProductBanner,
    Stethoscopes,
    DisposableProtectiveApparel,
    ExamGloves,
    FallManagementFootwear,
    IncontinenceProducts,
    PressureInfusionBags

  },
  computed: {
    currentComponent() {
      const category = this.$route.params.category;

      // Dynamically determine the component to render
      switch (category) {
        case 'stethoscopes':
          return Stethoscopes;
        case 'disposable-protective-apparel':
          return DisposableProtectiveApparel;
        case 'exam-gloves':
          return ExamGloves;
        case 'fall-management-footwear':
          return FallManagementFootwear;
        case 'incontinence-products':
          return IncontinenceProducts;
        case 'pressure-infusion-bags':
          return PressureInfusionBags;
        default:
          return null; // Or a default component if no match
      }
    }
  }
};
</script>

<style scoped>
.container {
  min-height: 60vh;
  padding-top: 10vh;
  /* Adjust the value to match your navbar's height */
}


.no-category-message {
  text-align: center;
  padding: 30px;
}

.no-category-message ul {
  list-style: none;
  /* Remove default list markers */
  padding: 0;
  /* Remove default padding */
}

.no-category-message li {
  margin-bottom: 1.5rem;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
    /* Subtle shadow */
  /* Add space between list items */
}

.no-category-message a {
  display: block;
  /* Make the entire list item clickable */
  padding: 0.75rem 1.25rem;
  /* Add padding for a button-like appearance */
  background-color: var(--bs-teal);
  /* Use your teal color */
  color: white;
  border-radius: 0.25rem;
  /* Add rounded corners */
  transition: background-color 0.3s ease;
  /* Add a smooth transition for hover effect */
}

.no-category-message a:hover {
  background-color: #346160;
    /* Darker teal on hover */
    transform: scale(1.02);
  /* Slightly darker teal on hover */
}
.category-list {
  list-style: none;
  padding: 0;
}

.category-list li {
  margin-bottom: 1.5rem;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

.category-list a {
  display: block;
  padding: 1rem 1.5rem;
  background-color: var(--bs-teal);
  color: white;
  border-radius: 0.25rem;
  transition: all 0.3s ease;
}


</style>