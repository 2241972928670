<template>
  <div class="content-wrapper">
    <div v-for="product in fallFootwearData" :key="product.id" class="fall-management-footwear-card">
      <div class="row product-page-card">
        <div class="col-md-6">
          <div class="carousel slide" :id="'carousel'+product.id" data-bs-ride="carousel">
            <div class="carousel-inner">
              <div v-for="(image, index) in product.images" :key="image"
                :class="['carousel-item', { active: index === 0}]">
                <img :src="getImagePath(image)" :alt="product.title">
              </div>
            </div>
            <button class="carousel-control-prev" type="button" :data-bs-target="'#carousel'+ product.id"
              data-bs-slide="prev">
              <span class="carousel-control-prev-icon" aria-hidden="true"></span>
              <span class="visually-hidden">Previous</span>
            </button>
            <button class="carousel-control-next" type="button" :data-bs-target="'#carousel'+ product.id"
              data-bs-slide="next">
              <span class="carousel-control-next-icon" aria-hidden="true"></span>
              <span class="visually-hidden">Next</span>
            </button>
          </div>
        </div>
        <div class="col-md-6">
          <h1>{{ product.title }}</h1>
          <p>{{ product.description }}</p>
          <div class="table-container">
            <div v-if="product.qualityStandards && product.qualityStandards.length">
              <h4><strong>Quality Standard</strong></h4>
              <ul>
                <li v-for="(standard, index) in product.qualityStandards" :key="index">
                  <strong>{{ standard.standard }}:</strong> {{ standard.description }}

                </li>
              </ul>
            </div>
            <div class="table-container">
              <h4><strong>Ordering Information</strong></h4>
              <table class="table table-striped table-sm">
                <thead>
                  <tr>
                    <th>Size</th>
                    <th>Item Number</th>
                    <th>Color</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="variant in product.orderingInformation" :key="variant.itemNumber">
                    <td>{{ variant.size }}</td>
                    <td>{{ variant.itemNumber }}</td>
                    <td>{{ variant.color }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

      </div>



    </div>
  </div>
</template>

<script>
import fallFootwearData from '@/data/fallManagementFootwear.json';

export default {
  data() {
    return {
      fallFootwearData: fallFootwearData, // Load all product data
    };
  },
  methods: {
    getImagePath(imagePath) {
      return require('@/' + imagePath); // Use require to resolve the path
    },
  },
}
</script>

<style scoped>
.fall-management-footwear-card {
  width: 100%;
  max-width: 800px;
  margin: 20px;
}
</style>

